import React, {lazy, Suspense, useEffect, useState} from 'react';
import {renderLoaderBlue} from "../../components/common/loader/circularLoader";
import Grid from '@mui/material/Grid';
import './homePage.scss';
import {v4 as uuidv4} from "uuid";
import AddIcon from '@mui/icons-material/Add';
import DefaultMenu from "../../components/menu/DefaultMenu";
import {ListItem, ListItemButton} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {hasUser} from "../../utils/cookies";
import FooterComponent from "../../components/footer/FooterComponent";
import {Link, useLocation} from "react-router-dom";
import Button from "@mui/material/Button/Button";
import CarouselSwipableSection from "./sectionCarousel";
import platform_screenshot from "../../images/home/screenshot.png";
import media_kit_screenshot from "../../images/home/mediakit-builder.png";
import ai_generated_emails from "../../images/home/ai_emails_screenshot.png";
import sponsorship_leads from "../../images/home/sponsorship_leads.jpg";
import contract_ai from "../../images/home/agreement classified.png";
import community from "../../images/home/comunity_screen.png";
import {ReactComponent as CheckedBoxIcon} from "../../icons/Checkbox_checked.svg";
import {ReactComponent as Checkbox_reversed_checked} from "../../icons/Checkbox_reversed_checked.svg";
import Switch from "@mui/material/Switch/Switch";
import styled from "@emotion/styled";
import Stack from "@mui/material/Stack";

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 44,
    height: 24,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(15px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: 'white'
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 20,
        height: 20,
        borderRadius: "50%",
        backgroundColor: '#2715ff',
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 24 / 2,
        opacity: 1,
        backgroundColor: 'white',
        boxSizing: 'border-box',
    },
}));


const HomePageComponent = () => {
    const location = useLocation();
    const [selectedType, setSelectedType] = useState('annual');
    const [expanded, setExpanded] = useState(null);

    useEffect(()=>{
        if(location.state && 'goToParam' in location.state){
            setTimeout(() => {
                goToAnchor(location.state.goToParam)
            }, 150)
        }
    },[location.state, location.state?.goToParam]);

    const goToAnchor = (anchorId) => {
        let element = document.getElementById(anchorId);
        if (element) {
            let headerOffset = 40;
            if(anchorId === 'pricing'){
                headerOffset = -80;
            }
            let elementPosition = element.getBoundingClientRect().top;
            let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    };

    return <Grid className='home-page'>
        <DefaultMenu />
        <Grid className='top-section' container alignItems="center" justifyContent="center">
            <Grid item xs={11} sm={11} md={5} lg={5} px="8px">
                <h4 className="color-white heading">We Help Creators Master Brand Sponsorships </h4>
                <div className="text-block color-white ">
                    Join <em>300,000+</em> creators who are using Social Bluebook's
                    platform to land <em>more</em> deals.
                </div>
                <div>
                    <Link to={'/get-started'}>
                        <Button color="yellow_2" variant="contained">
                            <strong className="bold-text-2">
                                Try for Free
                            </strong>
                        </Button>
                    </Link>

                </div>
            </Grid>
            <Grid item xs={11} sm={11} md={5} lg={5} px="8px" display={{ xs: "none", sm: "none", md: "block" }}>
                <div className="video-player">
                    <iframe
                        scrolling="no" allowFullScreen="" title="SBB Hero Jan 24"
                        width="100%"
                        height="100%"
                        src={"https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fplayer.vimeo.com%2Fvideo%2F904844792%3Fh%3D286fa5bbf2%26app_id%3D122963&dntp=1&display_name=Vimeo&url=https%3A%2F%2Fvimeo.com%2F904844792%2F286fa5bbf2&image=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1785731342-ff32ecce4ff79411fac1da4226b81d7e9f94b37777f6244bd8dc1bc3b79806ab-d_1280&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=vimeo"} />
                </div>
           </Grid>
        </Grid>
        <Grid className="carousel-section" container justifyContent="center" alignItems="center" id="testimonials">
            <CarouselSwipableSection />
        </Grid>
        <Grid className="hero-stack" container justifyContent="center" alignItems="center">
            <Grid xs={11} sm={11} md={10} lg={9} xl={8} item container
                  justifyContent="center" alignItems="center" direction="column">
                <h1>Connect Platforms &amp; See Your Worth</h1>
                <div>Instantly connect any of your social platforms and see how much you can be getting paid.<br />&nbsp;</div>
                <img
                    src={platform_screenshot}
                    className="hero-image" />
            </Grid>
        </Grid>
        <Grid className="hero-heading" container justifyContent="center" alignItems="center">
            <Grid xs={12} item container justifyContent="center" alignItems="center">
                <Grid item xs={11} sm={6} md={5} lg={4} xl={4} px={4}>
                    <h1>Media Kit Builder</h1>
                    <p>
                        Create compelling media kits showcasing your analytics.
                        This is the best way to show to brands that you are the right creator to work with and an
                        essential piece to your creator portfolio.
                    </p>
                </Grid>
                <Grid item xs={11} sm={6} md={5} lg={4} xl={4} px={4}>
                    <img src={media_kit_screenshot} className="hero-image" />
                </Grid>
            </Grid>
            <Grid xs={12} item container justifyContent="center" alignItems="center">
                <Grid item xs={11} sm={6} md={5} lg={4} xl={4} px={4} display={{xs: "none", sm: "block", md: "block"}}>
                    <img
                        src={ai_generated_emails}
                        className="hero-image" />
                </Grid>
                <Grid item xs={11} sm={6} md={5} lg={4} xl={4} px={4}>
                    <h1>AI Generated Outreach Emails</h1>
                    <p>
                        Need some help drafting an email to a brand that you want to work with?
                        Use AI to compose the email for you and then make whatever edits you want to it.
                    </p>
                </Grid>
                <Grid item xs={11} sm={6} md={5} lg={4} xl={4} px={4} display={{xs: "block", sm: "none", md: "none"}}>
                    <img
                        src={ai_generated_emails}
                        className="hero-image" />
                </Grid>
            </Grid>
        </Grid>
        <Grid className="hero-stack" container justifyContent="center" alignItems="center" >
            <Grid xs={11} sm={11} md={10} lg={9} xl={8} item container
                  justifyContent="center" alignItems="center" direction="column">
                <h1>Sponsorship Leads Updated Weekly</h1>
                <div>View our extensive list of the latest spending brands. New leads are added weekly.<br />&nbsp;</div>
                <img
                    src={sponsorship_leads}
                    className="hero-image" />
            </Grid>
        </Grid>
        <Grid className="hero-heading hero-yellow" container justifyContent="center" alignItems="center">
            <Grid xs={12} item container justifyContent="center" alignItems="center">
                <Grid item xs={11} sm={6} md={5} lg={4} xl={4} px={4} display={{xs: "none", sm: "block", md: "block"}}>
                    <img
                        src={contract_ai}
                        className="hero-image" />
                </Grid>
                <Grid item xs={11} sm={6} md={5} lg={4} xl={4} px={4}>
                    <h1>AI Contract Generator <br />& Redline Reviewer</h1>
                    <p>
                        Easy input the terms of your agreement and let our AI Contract Generator do the rest,
                        providing you with an editable document to share with your brand partners.
                        Upload any agreement and our Redline Reviewer will call out specific sections and advice
                        for each so all your bases are covered.
                    </p>
                </Grid>
                <Grid item xs={11} sm={6} md={5} lg={4} xl={4} px={4} display={{xs: "block", sm: "none", md: "none"}}>
                    <img
                        src={contract_ai}
                        className="hero-image" />
                </Grid>
            </Grid>
            <Grid xs={12} item container justifyContent="center" alignItems="center">
                <Grid item xs={11} sm={6} md={5} lg={4} xl={4} px={4}>
                    <h1>
                        Live Streams,<br />
                        24/7 Chat Support <br />
                        & More!
                    </h1>
                    <p>
                        Join the Social Bluebook community to engage with other creators as well as our coaches.
                        We're here with you along your journey!
                    </p>
                </Grid>
                <Grid item xs={11} sm={6} md={5} lg={4} xl={4} px={4}>
                    <img src={community}/>
                </Grid>
            </Grid>
        </Grid>

        <Grid className="hero-heading get-started" container direction="column"
              justifyContent="center"
              id="pricing"
              alignItems="center">
            <Grid xs item pb={6}>
                <h1>Try Pro Free for 14 Days</h1>
            </Grid>
            <Grid xs item>
                <Link to={'/get-started'}>
                    <Button variant="contained" color="yellow_2" size="large">Try for Free</Button>
                </Link>
            </Grid>
            <Grid xs item container justifyContent="center" pb="40px">
                <Grid item xs={11} sm={11} md={6} lg={5} xl={4} paddingRight={{xs: 0, sm: 0, md: 1, lg: 1, xl: 1}}>
                    <Grid className="free-sub-container">
                            <h1>Free</h1>
                            <h2>$0</h2>
                            <Grid className="subscriptions">
                                <ul className="pointer-list">
                                    <li>
                                        <CheckedBoxIcon />
                                        <span>
                                            Connect one social account to see how much you could be charging brands for sponsored content
                                        </span>
                                    </li>
                                    <li>
                                        <CheckedBoxIcon />
                                        <span>
                                            Start a Media Kit to showcase your services to brands
                                        </span>
                                    </li>
                                    <li>
                                        <CheckedBoxIcon />
                                        <span>
                                           Join the #1 Sponsorships Community in the World
                                        </span>
                                    </li>
                                </ul>
                            </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11} sm={11} md={6} lg={5} xl={4} paddingLeft={{xs: 0, sm: 0, md: 1, lg: 1, xl: 1}}>
                    <Grid>
                        <Grid container alignItems="center" justifyContent="center" py={2}>
                            <Stack direction="row" spacing={1} alignItems="center" className="period-switch">
                                <p className={selectedType === 'monthly' ? 'active' : 'non-active'}>Monthly</p>
                                <AntSwitch checked={selectedType === 'annual'}
                                           onChange={(e, newValue) => {
                                               if(newValue){
                                                   setSelectedType('annual');
                                               }else{
                                                   setSelectedType('monthly');
                                               }
                                           }}
                                           inputProps={{ 'aria-label': 'ant design' }} />
                                <p className={selectedType === 'monthly' ? 'non-active' : 'active'}>Annually</p>
                            </Stack>
                        </Grid>
                        <div class="plan_card">
                            <div class="price-badge">
                                <div>Most Popular</div>
                            </div>
                            <h1>Professional</h1>
                            <h2>{selectedType == 'monthly' ? '$29.95' : '$25.00'}/mo <br />
                                <small>Billed {selectedType === 'monthly' ? 'Monthly' : 'Annually'}</small></h2>
                            <Grid className="subscriptions">
                                <ul className="pointer-list">
                                    <li>
                                        <Checkbox_reversed_checked />
                                        <span>
                                            Free 1-1 brand sponsorship audit with one of our Sponsorship Experts
                                        </span>
                                    </li>
                                    <li>
                                        <Checkbox_reversed_checked />
                                        <span>
                                            Unlock your value and generate media kit with unlimited social accounts
                                        </span>
                                    </li>
                                    <li>
                                        <Checkbox_reversed_checked />
                                        <span>
                                           Weekly Sponsorship leads for brands who are actively partnering with creators like you
                                        </span>
                                    </li>
                                    <li>
                                        <Checkbox_reversed_checked />
                                        <span>
                                           AI-based assistant for drafting brand outreach emails
                                        </span>
                                    </li>
                                    <li>
                                        <Checkbox_reversed_checked />
                                        <span>
                                           Quote &amp; Contract generators to elevate your professionalism and land higher paying deals
                                        </span>
                                    </li>
                                    <li>
                                        <Checkbox_reversed_checked />
                                        <span>
                                           Easily manage all of your Brand Partnerships with Deal Manager &amp; Calendar
                                        </span>
                                    </li>
                                </ul>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs item>
                <Link to={'/get-started'}>
                    <Button variant="contained" color="yellow_2" size="large">Try for Free</Button>
                </Link>
            </Grid>
        </Grid>

        <FooterComponent />
    </Grid>
}

export default HomePageComponent;
