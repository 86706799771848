import React, {lazy, Suspense, useState} from 'react';

// core libraries
import {
    Navigate,
    Route,
    Routes,
    useLocation
} from "react-router-dom";

//custom tools
import {pageTrack} from "./utils/tracker";
import {getCookie, hasUser} from './utils/cookies';
import {getRole, getUser, hasActiveSubscription, saveUserSession} from "./utils/user";
import {getUserService} from "./redux/user/requests";

//custom components
import {
    roles,
    hiddenMenuPaths,
    hiddenPartialPaths,
    menuStructure,
    menuStructureAdv,
    user_menu, adv_user_menu
} from "./utils/constants";
import Menu, {useMenuStyles} from "./components/menu/ComponentMenu";
import PrivateRoute from "./components/privacy/privateRouter";

import {FullPageLoading} from "./components/common/loader/circularLoader";

//custom plugins
const AddPlatformDrawer = lazy(() => import('./components/talent/addTalent'));
const GlobalMessageSystem = lazy(() => import('./components/globalMessageSystem'));
const UpgradeComponent = lazy(() => import('./components/upgrade/upgradeComponent'));
const SbbChatSystem = lazy(() => import('./components/chatSystem/chat'));
const ZenDesk = lazy(() => import('./components/chatSystem/ZenDesk'));
const AiGeneratorComponent = lazy(() => import('./components/aiGenerator/aiGeneratorComponent'));

//pages
const NotFound = lazy(() => import('./pages/notFound/notFound'));

//public pages
// import HomePage from './pages/homeRedirect';
import HomePage from './pages/homePage/homePage';
import AboutUsPage from './pages/aboutUs/aboutUs';
import TermsOfUseComponent from './pages/termsOfUse/termsOfUse';
import PrivacyPolicyComponent from './pages/privacyPolicy/privacyPolicy';
const RegistrationPage = lazy(() => import('./pages/auth/registerComponent'));
const RegistrationAdvPage = lazy(() => import('./pages/auth/registerAdvComponent'));
// const HomePage = lazy(() => import('./pages/homePage/homePage'));
// const AboutUsPage = lazy(() => import('./pages/aboutUs/aboutUs'));

// const TermsOfUseComponent = lazy(() => import('./pages/termsOfUse/termsOfUse'));
// const PrivacyPolicyComponent = lazy(() => import('./pages/privacyPolicy/privacyPolicy'));

const LoginComponent = lazy(() => import('./pages/auth/loginComponent'));
const LogoutComponent = lazy(() => import('./pages/auth/logoutComponent'));
const ConfirmPasswordComponent = lazy(() => import('./pages/auth/confirmComponent'));
const ForgetComponent = lazy(() => import('./pages/auth/forgetComponent'));

// under user session
const MediaKit = lazy(() => import('./pages/mediaKit/mediaKit'));
const ValuationsComponent = lazy(() => import('./pages/valuations/valuationsView'));
const QuoteCalculatorComponent = lazy(() => import('./pages/quoteCalculator/quoteCalculatorView'));
const DashboardComponent = lazy(() => import('./pages/dashboard/dashboardView'));
const DashboardAdvComponent = lazy(() => import('./pages/advDash/DashboardAdvComponent'));
const Insights = lazy(() => import('./pages/valuations/insights/insightsComponent'));
const dealTrackerComponent = lazy(() => import('./pages/brandDeals/dealTracker'));
const editDealComponent = lazy(() => import('./pages/brandDeals/dealEditor'));
const Calendar = lazy(() => import('./pages/brandDeals/dealCalendar'));
const SubscriptionComponent = lazy(() => import('./pages/subscriptions/subscription'));
const ConfirmEmail = lazy(() => import('./pages/auth/confirmEmail'));
const EmailComposer = lazy(() => import('./pages/emailComposer/emailComposer'));
const CancelSubscriptionComponent = lazy(() => import('./pages/subscriptions/cancelSubscription'));
const BrandLeads = lazy(()=>import('./pages/SponsorshipLeads/BrandLeads'));
const PartnerPerksComponent = lazy(() => import('./pages/partnerPerks/partnerPerksView'));

const CreatorCoaching = lazy(() => import('./pages/creatorCoaching/creatorCoaching'));
const MyAccount = lazy(() => import('./pages/account/myAccount'));
const AdvAccount = lazy(() => import('./pages/advAccount/myAccount'));
const Billing = lazy(() => import('./pages/billing/billing'));
const UpcomingEvents = lazy(() => import('./pages/CirclePages/UpcomingEvents'));

const JoinIntensive = lazy(() => import('./pages/CirclePages/JoinIntensive'));
const SponsorshipOne = lazy(() => import('./pages/CirclePages/SponsorshipsOne'));
const Contract = lazy(() => import('./pages/contract/contract'));
const CircleHome = lazy(() => import('./pages/CirclePages/CircleHome'));
const VoucherPage = lazy(() => import('./pages/voucher/voucherLanding'));
const ContractAnalisePage = lazy(() => import('./pages/contractAnalise/contractAnalise'));

import useClasses from "./utils/themeUtils";
import SbbTour from "./components/sbbTours/sbbTour";
import Page from "./components/page/page";
import {useNavigate} from "react-router";
import ErrorBoundary from "./components/error/errorBoundary";
import EmailConfirmationWall from "./pages/auth/EmailConfirmationWall";
import {DASHBOARD} from "./utils/navigation";
import {FORBIDDEN, REJECTED} from "./utils/serverCode";

const App = () => {
    let loggedIn = !!hasUser();
    let userRole = getRole();
    const location = useLocation();
    const navigate = useNavigate();
    const [hideMenu, setHideMenu] = useState(false);
    let hasPremium = hasActiveSubscription();
    const [userMenuStructure, setUserMenuStructure] = useState(menuStructure);
    const [userStructure, setUserStructure] = useState(user_menu);
    const [userType, setUserType] = useState('creator');

    React.useEffect(()=>{
        if(window.ga){
            window.ga('create', 'UA-102871126-1', 'auto');
        }
    },[window.ga]);

    React.useEffect(()=>{
        if(window.google_tag_manager){
            //when google tag manager is available, run this only once to register the user using this page:
            window.gtm_push = window.google_tag_manager.dataLayer = window.dataLayer = window.dataLayer || [];
            if(window.gtm_push){
                const user = getUser();
                if(user){
                    window.gtm_push.push({
                        'emailAddress': user['email']
                    });
                }
            }
        }
    },[window.google_tag_manager]);

    React.useEffect(()=>{
        trackPageView();
    },[location]);

    const trackPageView = () => {
        pageTrack(window.location.pathname);
    };

    const userVerificationRedirect = () => {

        getUserService().then(result=>{
            if(saveUserSession(result)){
                // check where the user should go:
                setRedirect(location.pathname);
            }
        }).catch(error => {
            if(location.pathname === '/get-started'){
                setRedirect(location.pathname);
            }
            if(location.pathname === '/'){
                setRedirect(location.pathname);
            }
            if(location.pathname === '/home'){
                setRedirect(location.pathname);
            }
            if(location.pathname === '/creator-coaching'){
                setRedirect(location.pathname);
            }
            if(location.pathname === '/privacy-policy'){
                setRedirect(location.pathname);
            }
            if(location.pathname === '/terms-of-use'){
                setRedirect(location.pathname);
            }
            if(location.pathname === '/forgot'){
                setRedirect(location.pathname);
            }
            if(location.pathname === '/subscriptions'){
                setRedirect(location.pathname);
            }else{
                if(error?.response?.status === FORBIDDEN || error?.response?.status === REJECTED){
                    if(location.state?.prevPath !== '/logout'){
                        navigate('/logout');
                    }
                }
            }
        });
    }
    const getUserMenuStructure = () => {
        const user = getUser()
        const user_creator = (roles.CREATOR && roles.CREATOR.indexOf(user?.role) >= 0);
        const user_adv = (roles.ADVERTISER && roles.ADVERTISER.indexOf(user?.role) >= 0);
        let default_structure = menuStructure;
        let default_user_structure = user_menu;
        let user_type = 'creator';
        if(user_creator){
            default_structure = menuStructure;
            default_user_structure = user_menu;
            user_type = 'creator';
        }
        if(user_adv){
            default_structure = menuStructureAdv;
            default_user_structure = adv_user_menu;
            user_type = 'advertiser';
        }
        setUserMenuStructure(default_structure);
        setUserStructure(default_user_structure);
        setUserType(user_type);
    }

    const [redirect, setRedirect] = useState(null);
    React.useEffect(() => {
        if(getCookie('id') !== ''){
            userVerificationRedirect();
        }
        if(location.pathname === '/subscriptions'){
            setRedirect(location.pathname);
        }
        if(location.pathname === '/get-started'){
            setRedirect(location.pathname);
        }
        if(location.pathname.indexOf('voucher') >= 0){
            setRedirect(location.pathname);
        }
        getUserMenuStructure();
    },[]);

    React.useEffect(() => {
        //post logout refresh the page - csrf:
        if(location.state?.prevPath && location.state?.prevPath === '/logout'){
            navigate('/login');
            window.location.reload();
        }
        loggedIn = !!hasUser();
        userRole = getRole();

        if(!loggedIn){
            return setHideMenu(true);
        }else{
            setHideMenu(false);
        }

        if(location.state && 'noMenu' in location.state && loggedIn){
            setHideMenu(location.state.noMenu);
        }

        hiddenMenuPaths.map((path)=>{
            if(path === location.pathname){
                setHideMenu(true);
            }
        });

        hiddenPartialPaths.map((path)=>{
            const location_name = 'https://' + window.location.host + path
            if(window.location.href.indexOf(location_name) === 0){
                setHideMenu(true);
            }
        });

        hasPremium = hasActiveSubscription();

        getUserMenuStructure();

        return () => {
            setHideMenu(true);
        }

    }, [location, loggedIn, hasUser, userRole]);


    const classes = useClasses(useMenuStyles);
    return (
        <div className={!hideMenu  ? classes.root : ''}>
            {!hideMenu ? <>
                <Menu structure={userMenuStructure} user_structure={userStructure}/>
                {loggedIn ? <Suspense fallback={null}>
                                <AddPlatformDrawer />
                            </Suspense> : null}
            </> : null }

            <main className={!hideMenu  ? classes.content : ''}>
                <Routes>
                    {/* the open routes */}
                    <Route exact path={'/terms-of-use'} element={
                        <TermsOfUseComponent />
                    }
                    />

                    <Route exact path={'/privacy-policy'} element={
                        <PrivacyPolicyComponent />
                    }
                    />

                    <Route exact path='/creator-coaching' element={
                        <Suspense fallback={<FullPageLoading />}>
                            <CreatorCoaching title="Creator Coaching" />
                        </Suspense>
                    } />

                    {/*auth routes*/}
                    <Route exact path='/get-started' element={
                        <Suspense fallback={<FullPageLoading />}>
                            <RegistrationPage title="Get Started" />
                        </Suspense>
                    }/>

                    <Route exact path='/brand-register' element={
                        <Suspense fallback={<FullPageLoading />}>
                            {/*<RegistrationAdvPage title="Get Started Adv" />*/}
                        </Suspense>
                    }/>

                    <Route exact path='/logout' element={
                        <Suspense fallback={<FullPageLoading />}>
                            <LogoutComponent title="Logout" />
                        </Suspense>
                    }/>

                    <Route exact path='/login' element={
                        <Suspense fallback={<FullPageLoading />}>
                            {loggedIn ? <Navigate to={redirect ? redirect : DASHBOARD} /> : <LoginComponent title="Login" redirect={redirect}/>}
                        </Suspense>
                    }/>

                    <Route exact path='/forgot' element={
                        <Suspense fallback={<FullPageLoading />}>
                            {loggedIn ? <Navigate to={DASHBOARD} /> : <ForgetComponent title="Forgot Password" />}
                        </Suspense>
                    }/>

                    <Route exact path='/password/confirm/:uid/:token' element={
                        <Suspense fallback={<FullPageLoading />}>
                            <ConfirmPasswordComponent title="Confirm Password"/>
                        </Suspense>
                    }/>

                    <Route exact path='/subscriptions' element={
                        <Suspense fallback={<FullPageLoading />}>
                            {loggedIn ? <SubscriptionComponent title="Prices"/> : <Navigate to={'/login'} />}
                        </Suspense>
                    }/>

                    <Route exact path='/' element={
                        loggedIn ? <Navigate to={DASHBOARD} /> :
                            <HomePage />

                    } />

                    <Route exact path='/home' element={
                        <HomePage />
                    } />

                    <Route exact path='/about-us' element={
                        <AboutUsPage />
                    } />

                    {/* Advertisers Pages */}
                    <Route path='/account' element={
                        <PrivateRoute component={AdvAccount}
                                      title="My Account"
                                      userRole={userRole}
                                      permittedRoles={roles.ADVERTISER}/>
                    } />

                    {/* Creators Pages */}
                    <Route path={DASHBOARD} element={
                        <PrivateRoute component={userType === 'creator' ? DashboardComponent : DashboardAdvComponent}
                                      title="Dashboard"
                                      userRole={userRole}
                                      permittedRoles={[...roles.CREATOR,...roles.ADVERTISER]}/>
                    } />

                    <Route path='/valuations' element={
                        <PrivateRoute component={ValuationsComponent}
                                      title="Valuations"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/calculator' element={
                        <PrivateRoute component={QuoteCalculatorComponent}
                                      title="Quote Calculator"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/contract-reviewer' element={
                        <PrivateRoute component={ContractAnalisePage}
                                      title="Contract Analysis"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route exact path='/insights/:talentId' element={
                        <PrivateRoute component={Insights}
                                      title="Insights"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/my-media-kit' element={
                        <PrivateRoute component={MediaKit}
                                      title="Media Kit"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/sponsorship-leads' element={
                        <PrivateRoute component={BrandLeads}
                                      title="Sponsorship Leads"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/calendar' element={
                        <PrivateRoute component={Calendar}
                                      title="Calendar"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/deal-manager' element={
                        <PrivateRoute component={dealTrackerComponent}
                                      title="Brand Deals"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/partner-perks' element={
                        <PrivateRoute component={PartnerPerksComponent}
                                      title="Partner Perks"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/community' element={
                        <PrivateRoute component={CircleHome}
                                      title="Community"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/archived-deal-manager' element={
                        <PrivateRoute component={dealTrackerComponent}
                                      title="Brand Deals"
                                      relay={{status: 'archived'}}
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/add-deal' element={
                        <PrivateRoute component={editDealComponent}
                                      title="Brand Deals"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/edit-deal/:dealId' element={
                        <PrivateRoute component={editDealComponent}
                                      title="Brand Deals"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/my-account' element={
                        <PrivateRoute component={MyAccount}
                                      title="My Account"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/billing' element={
                        <PrivateRoute component={Billing}
                                      title="Billing"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/cancel-subscription' element={
                        <PrivateRoute component={CancelSubscriptionComponent}
                                      title="Cancel Subscription"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/compose-email' element={
                        <PrivateRoute component={EmailComposer}
                                      title="Compose Email"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/upcoming-livestreams' element={
                        <PrivateRoute component={UpcomingEvents}
                                      title="Upcoming Livestreams"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/sponsorship-intensive' element={
                        <PrivateRoute component={JoinIntensive}
                                      title="Join Intensive"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/voucher/:voucher' element={
                        <PrivateRoute component={VoucherPage}
                                      title="Voucher Activation"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/sponsorship-101' element={
                        <PrivateRoute component={SponsorshipOne}
                                      title="Invoice Templates"
                                      userRole={userRole}
                                      permittedRoles={roles.CREATOR}/>
                    } />

                    <Route path='/contract-generator' element={
                        <Suspense fallback={<FullPageLoading/>}>
                            <PrivateRoute component={Contract}
                                          title="Contract Generator"
                                          userRole={userRole}
                                          permittedRoles={roles.CREATOR}/>
                        </Suspense>
                    }/>

                    <Route exact path='/confirm/:email_code' element={
                        <Suspense fallback={<FullPageLoading/>}>
                            <Page component={ConfirmEmail} title={'Confirm Email'}/>
                        </Suspense>
                    }/>

                    <Route path='/confirm-email' element={
                        <Suspense fallback={<FullPageLoading/>}>
                            <Page component={EmailConfirmationWall} title={'Confirm your email'}/>
                        </Suspense>
                    }/>

                    <Route exact path='/profile/:customURL' element={
                        <Suspense fallback={<FullPageLoading />}>
                            <MediaKit />
                        </Suspense>
                    } />

                    <Route element={
                        <Suspense fallback={<FullPageLoading />}>
                            <NotFound />
                        </Suspense>
                    } />
                </Routes>
            </main>
            {loggedIn &&  <Suspense fallback={null}>
                <GlobalMessageSystem />
            </Suspense>}
            {loggedIn &&  <Suspense fallback={null}>
                <UpgradeComponent />
            </Suspense>}

            <ErrorBoundary>
                <SbbTour />
            </ErrorBoundary>

            {loggedIn && <Suspense fallback={null}>
                {/* ZenDesk - hasPremium */}
                { false ? <SbbChatSystem /> : <ZenDesk />}
            </Suspense>}

            {loggedIn && hasPremium && <Suspense fallback={null}>
                <AiGeneratorComponent />
            </Suspense>}
        </div>
    );
};
export default App;
