import axios from "axios";
import {baseUrl, transformRequest} from "../../utils/baseurl";

export const getLeads = (initialFilter) => {
    const GET_DEALS_ENDPOINT = baseUrl('brand-leads/');
    return apiController(GET_DEALS_ENDPOINT, 'GET', initialFilter)
};

export const getDeals = (initialFilter) => {
    const GET_DEALS_ENDPOINT = baseUrl('brand-deals/');
    return apiController(GET_DEALS_ENDPOINT, 'GET', initialFilter)
};

export const getDealsReport = () => {
    const GET_DEALS_ENDPOINT = baseUrl('brand-deals-report/');
    return apiController(GET_DEALS_ENDPOINT, 'GET')
};

export const getDeal = (dealId) => {
    const GET_DEAL_ENDPOINT = baseUrl('brand-deal/?deal_id=' + dealId);
    return apiController(GET_DEAL_ENDPOINT, 'GET')
};

export const getDealLogo = (brand) => {
    const GET_DEAL_ENDPOINT = baseUrl('brand-leads-logo/');
    const data = {
        'brand' : brand,
    };
    return apiController(GET_DEAL_ENDPOINT, 'POST', data)
};

export const removeDeal = (dealId) => {
    const GET_DEAL_ENDPOINT = baseUrl('brand-deal/?deal_id=' + dealId);
    return apiController(GET_DEAL_ENDPOINT, 'DELETE')
};

const DEAL_URL = 'brand-deal/';
export const archiveDeal = (dealId) => {
    const GET_DEAL_ENDPOINT = baseUrl(DEAL_URL);
    const data = {
        'deal_id' : dealId,
        'archive': true,
    };
    return apiController(GET_DEAL_ENDPOINT, 'POST', data);
};

export const activateDeal = (dealId) => {
    const GET_DEAL_ENDPOINT = baseUrl(DEAL_URL);
    const data = {
        'deal_id' : dealId,
        'archive': false,
    };
    return apiController(GET_DEAL_ENDPOINT, 'POST', data);
};

export const switchDeal = (dealId, attribute, value) => {
    const GET_DEAL_ENDPOINT = baseUrl(DEAL_URL);
    const data = {
        'deal_id' : dealId,
        attribute,
        value,
        'action': 'switch',
    };
    return apiController(GET_DEAL_ENDPOINT, 'POST', data);
};

export const createDeal = (data) => {
    const GET_DEAL_ENDPOINT = baseUrl(DEAL_URL);
    return apiController(GET_DEAL_ENDPOINT, 'POST', data);
};

export const newDeal = (data) => {
    const GET_DEAL_ENDPOINT = baseUrl(DEAL_URL);
    return apiFilesCall(GET_DEAL_ENDPOINT, 'POST', data);
};

export const acceptTermsAndConditions = (data) =>{
    const GET_ADVERTISER_ENDPOINT = baseUrl('flexoffers/advertisers-toc/');
    return apiController(GET_ADVERTISER_ENDPOINT, 'POST', data);
};

export const removeFile = (fileId) => {
    const REMOVE_DEAL_FILE_ENDPOINT = baseUrl('brand-deal-file/?file_id=' + fileId);
    return apiController(REMOVE_DEAL_FILE_ENDPOINT, 'DELETE');
};

export const getDealReport = (dealId, type, customUrl) => {
    let GET_DEAL_REPORT_ENDPOINT = baseUrl('brand-deal/report/?deal_id=' + dealId + '&type=' + type);
    if(customUrl){
        GET_DEAL_REPORT_ENDPOINT = GET_DEAL_REPORT_ENDPOINT + '&custom_pointer=' + String(customUrl);
    }
    return apiController(GET_DEAL_REPORT_ENDPOINT, 'GET');
};

export const getDealTypes = () => {
    const GET_DEALS_ENDPOINT = baseUrl('brand-deals-upload-types/');
    return apiController(GET_DEALS_ENDPOINT, 'GET')
};

export const getSchedule = () => {
    const GET_DEALS_ENDPOINT = baseUrl('brand-deals-schedule/');
    return apiController(GET_DEALS_ENDPOINT, 'GET')
};

export const postSchedule = (data) => {
    const GET_DEALS_ENDPOINT = baseUrl('brand-deals-schedule/');
    return apiController(GET_DEALS_ENDPOINT, 'POST', data)
};

export const removeSchedule = (data) => {
    const GET_DEALS_ENDPOINT = baseUrl('brand-deals-schedule/remove/');
    return apiController(GET_DEALS_ENDPOINT, 'POST', data)
};

export const addScheduleType = (data) => {
    const GET_DEALS_ENDPOINT = baseUrl('brand-deals-schedule/add/');
    return apiController(GET_DEALS_ENDPOINT, 'POST', data)
};

export const getPromotions = (params) => {
    const GET_DEALS_ENDPOINT = baseUrl('flexoffers/promotions/');
    return apiController(GET_DEALS_ENDPOINT, 'GET', params)
};

export const getAdvertisers = (params) => {
    const GET_DEALS_ENDPOINT = baseUrl('flexoffers/advertisers/');
    return apiController(GET_DEALS_ENDPOINT, 'GET', params)
};

export const getFeaturedAdvertisers = (params) => {
    const GET_DEALS_ENDPOINT = baseUrl('flexoffers/featured-advertisers/');
    return apiController(GET_DEALS_ENDPOINT, 'GET', params)
};

export const getAdvertiser = (advId) => {
    const GET_DEALS_ENDPOINT = baseUrl(`flexoffers/advertisers/${advId}/`);
    return apiController(GET_DEALS_ENDPOINT, 'GET')
};

export const getStatsReport = (params) => {
    const GET_STATS_REPORTS = baseUrl('flexoffers/stats/')
    return apiController(GET_STATS_REPORTS, 'GET')
}

export const apiController = (url, method, data) => {
    const config = {
        method,
        url,
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        withXSRFToken: true,
    };
    if(data){
        if(method === 'GET'){
            config['params'] = data;
        }else{
            config['data'] = data;
        }
    }
    return axios.request(config)
        .then((response) => {
            return response;
        }).catch((error) => {
            console.log("==== service error 2 ========");
            console.log(error);
            return error;
        });
};

const apiFilesCall = (url, method, data) => {
    const config = {
        method,
        url,
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        withXSRFToken: true,
        transformRequest,
    };
    if(data){
        config['data'] = data;
    }
    return axios.request(config);
};
