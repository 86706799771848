import React from "react";

import {ReactComponent as FacebookIcon} from "../icons/talents/facebook.svg";
import {ReactComponent as YouTubeIcon} from "../icons/talents/youtube.svg";
import {ReactComponent as TwitterIcon} from "../icons/talents/twitter.svg";
import {ReactComponent as TikTokIcon} from "../icons/talents/tiktok.svg";
import {ReactComponent as TwitchIcon} from "../icons/talents/twitch.svg";
import {ReactComponent as InstagramIcon} from "../icons/talents/Instagram.svg";
import {ReactComponent as BlogIcon} from "../icons/talents/blogger.svg";
import DealTrackerImage from "../images/content/presentation-1.png";
import ValuationsImage from "../images/content/presentation-2.png";
import AffiliateMarketplaceImage from "../images/content/presentation-4.png";
import BrandDeals from "../images/brand_deals.jpg";
import {DASHBOARD} from "./navigation";
import InterestsIcon from '@mui/icons-material/Interests';

export const bannerElements = {
    'deal-manager': {
        image: DealTrackerImage,
        description: "Manage your on-going deals or look for new ones within our Brand Sponsorships",
        title: "How to use Brand Deal Manager & Calendar",
        video: 'https://youtu.be/TD2q5cm-gVE'
    },
    'quote-calculator': {
        description: "A tool to help more accurately price your sponsorship opportunities.",
        title: "How to use the Quote Calculator",
        video: 'https://youtu.be/rcz_mMV3slU'
    },
    'valuations': {
        image: ValuationsImage,
        title: "How to use Valuations",
        description: "Calculate how much you could be charging brands to do sponsored content for each one of your social media platforms.",
        video: 'https://youtu.be/Ggy_QNEit6k'
    },
    'media-kit': {
        image: AffiliateMarketplaceImage,
        title: "How to use Affiliate Marketplace",
        description: "",
        video: 'https://youtu.be/XJxqF34FfRQ'
    },
    'community': {
        image: null,
        title: 'Never heard of discord?',
        description: 'Here’s a quick step-by-step to get going.',
        video: 'https://youtu.be/fO6LWay6HnE'
    },
    'sponsorship_leads_next': {
        image: BrandDeals,
        link: '',
        page: '/sponsorship-leads',
        title: 'Congrats on connecting your account(s).',
        description: 'Check out the sponsorship leads we hunted down this month.',
        video: ''
    },
    'sponsorship-leads': {
        description: <>Sponsorship Leads saves you 100's of hours providing brands who are actively partnering with creators.
            If an email contact bounces contact us at <a href="mailto:support@socialbluebook.com">support@socialbluebook.com</a> and we will promptly replace and update the list.</>,
        title:  'How to use Sponsorship Leads',
        video: '',
        link: '',
    },
    'email-compose': {
        description: <>Need some help drafting an email to a brand that you want to work with? Use AI to compose the email for you and then make whatever edits you want to it.</>,
        title:  'How to use Email Composer (beta)',
        video: '',
        link: '',
    },
    'contract-reviewer': {
        description: <>Need some help understanding a contract that a brand or agency has sent you? Use AI to analyze any red flags or clauses in the contract that you should be concerned about.</>,
        title:  'How to use Contract Reviewer (beta)',
        video: '',
        link: '',
    },
    'contract-generator': {
        description: <>Need some help making a brand contract? Fill in the required information in order to generate your contract.</>,
        title:  'How to use Contract Generator',
        video: '',
        link: '',
    }
}

const LEARN = {
    boldItem: 'Learn',
    show: "title",
    svgSrc: 'LightIcon',
    multiple: true,
    id: 'f067f89ccb92389a6gfgggf758',
    menu: [
        {
            state: '/upcoming-livestreams',
            title: 'Upcoming Livestreams',
            svgSrc: '',
            show: 'title',
            classes: 'menu-icon-margin--8',
            id: 'eccbc87e4b5ce2fe28308fd9f2a7baassd1as',
        },
        // {
        //     state: '/sponsorship-101',
        //     title: 'Sponsorships 101',
        //     svgSrc: '',
        //     show: 'title',
        //     classes: 'menu-icon-margin--8',
        //     id: 'eccbc87e4b5ce2fe2830448asdfd9f2a7baassd1as',
        // },
        {
            state: {'pathname': 'https://community.socialbluebook.com/c/module-1/'},
            // state: '/sponsorship-intensive',
            title: 'Sponsorship Intensive',
            svgSrc: '',
            show: 'title',
            classes: 'menu-icon-margin--8',
            id: 'eccbc87e4b5ce2fe28308asdfd9f2a7baassd1as',
        },
    ]
}

export const menuStructureAdv = [
    {
        state: DASHBOARD,
        title: 'Dashboard',
        show: 'title',
        svgSrc: 'DashboardIcon',
        id: 'i2k4036v0eryakt2a77dfdfd7a0zw9iubv7p7'
    },
    {
        state: 'search',
        title: 'Creator Search',
        show: 'title',
        svgSrc: 'GroupIcon',
        id: 'c81e728d9d4c2f636f02344467f89cc148633'
    },

]

export const menuStructure = [
    {
        state: DASHBOARD,
        title: 'Dashboard',
        show: 'title',
        svgSrc: 'DashboardIcon',
        id: 'i2k4036v0eryakt2a77dfdfd7a0zw9iubv7p7'
    },
    {
        state: '/community',
        title: 'Community',
        show : 'title',
        svgSrc: 'GroupIcon',
        id: 'c81e728d9d4c2f636f02344467f89cc148633'
    },
    {
        boldItem: "Know Your Worth",
        show: 'title',
        svgSrc: 'ValuationsIcon',
        id: 'c4ca4238a0b923820dcc509a6f75844r',
        multiple: true,
        menu: [
            {
                state: '/valuations',
                title: 'Valuations',
                show: 'title',
                // svgSrc: 'ValuationsIcon',
                svgSrc: '',
                id: 'c4ca4238a0b923820dcc509a6f75849b'
            },
            {
                state: '/my-media-kit',
                title: 'Media Kit',
                show : 'title',
                // svgSrc: 'MediaKitIcon',
                svgSrc: '',
                id: 'c81e728d9d4c2f636f067f89cc14862c'
            }]
    },
    {
        state: '',
        title: '',
        upgradeAddon: true,
        boldItem: "Brand Outreach",
        show: 'title',
        svgSrc: 'EditIcon',
        id: 'c4ca4238a0b923820dchhjjc509a6f7asd5847t',
        multiple: true,
        menu: [
            {
                state: '/sponsorship-leads',
                title: 'Sponsorship Leads',
                // svgSrc: 'ValuationsIcon',
                svgSrc: '',
                show: 'title',
                id: 'd41d8cd98f00b204e980091198ecf84217e',
            },
            {
                state: '/compose-email',
                title: 'Email Composer',
                // svgSrc: 'ValuationsIcon',
                svgSrc: '',
                show: 'title',
                id: 'd41d8cd98f00b204e9834400998ecf84217e',
            },
        ]
    },
    {
        state: '',
        title: '',
        upgradeAddon: true,
        boldItem: "Negotiations",
        show: 'title',
        svgSrc: 'PartnerIcon',
        id: 'c4ca4238a0b9238458ff21c509a6f7asd5847t',
        multiple: true,
        menu: [
                {
                    state: '/calculator',
                    title: 'Quote Calculator',
                    svgSrc: '',
                    show: 'title',
                    classes: 'menu-icon-margin--8',
                    id: 'eccbc87e4b5ce2fe28308f3d9f2a7baassd1',
                },
                {
                    state: '/contract-generator',
                    title: 'Contract Generator',
                    svgSrc: '',
                    show: 'title',
                    id: 'gzsw9qwtk7axfouy07u7px9stmlnx11633330',
                },
                {
                    state: '/contract-reviewer',
                    title: 'Contract Reviewer',
                    // svgSrc: 'ValuationsIcon',
                    svgSrc: '',
                    show: 'title',
                    id: 'd41d8cd9aassd1232200998ecf84217e',
                },
        ]
    },
    {
        state: '',
        title: '',
        upgradeAddon: true,
        boldItem: "Deal Management",
        show: 'title',
        svgSrc: 'DocumentIcon',
        id: 'c4ca4238a0b923845821sc509a6f7asd5847t',
        multiple: true,
        menu: [
            {
                state: '/deal-manager',
                title: 'Deal Manager',
                svgSrc: '',
                show: 'title',
                id: 'eccbc87e4b5ce2fe28308fggd9f2a7baf441',
                // premium: true
            },
            {
                state: '/calendar',
                title: 'Calendar',
                svgSrc: '',
                show: 'title',
                id: 'eccbc87e4b5ce2fe28308677fd9f2a7baf31',
                // premium: true
            },
            // {
            //     state: '/invoice-templates',
            //     title: 'Invoice Templates',
            //     svgSrc: '',
            //     show: 'title',
            //     id: 'gzsw9qwtk7axfoasd231256hdfu7px9stmlnx630',
            //     // premium: true
            // },
        ]
    }
];

export const roles = {
    CREATOR: ['creator', 'creator_pending'],
    // ADVERTISER: ['advertiser', 'pending_advertiser', 'sponsor', 'pending_sponsor'],
    ADVERTISER: ['cancelled_adv'],
};

export const adv_user_menu = {
    state: '/account',
    title: 'My Account',
    opened: false,
    show: 'title',
    svgSrc: 'AccountIcon',
    aria: 'my-acount',
    multiple: true,
    id: '1679091c5a880faf6fb5e6087eb1b2dc',
    menu: [
        {
            state: '/account',
            action: 'account',
            title: 'My Account',
            svgSrc: 'PersonIcon',
            show: 'title',
            id: '8f14e45fceea167a5a36dedd4bea2543'
        },
        {
            state: '/logout',
            title: 'Logout',
            svgSrc: 'ExitToAppIcon',
            show: 'title',
            id: '8f14e45fceea167a5a36dedd4bea2445'
        }
    ]
}

export const user_menu = {
    state: '/my-account',
    title: 'My Account',
    opened: false,
    show: 'title',
    svgSrc: 'AccountIcon',
    aria: 'my-acount',
    multiple: true,
    id: '1679091c5a880faf6fb5e6087eb1b2dc',
    menu: [
        {
            state: '/my-account',
            action: 'account',
            title: 'My Account',
            svgSrc: 'PersonIcon',
            show: 'title',
            id: '8f14e45fceea167a5a36dedd4bea2543'
        },
        {
            state: '/billing',
            action: 'billing',
            title: 'Billing',
            svgSrc: 'ReceiptIcon',
            show: 'title',
            id: '8f14e45fceea167a5a36dedd4bea2544'
        },
        {
            state: '/logout',
            title: 'Logout',
            svgSrc: 'ExitToAppIcon',
            show: 'title',
            id: '8f14e45fceea167a5a36dedd4bea2445'
        }
    ]
};

export const pages = {
    'dashboard': 'Dashboard',
    'calculator': 'Quote Calculator',
    'valuations': 'Valuations',
    'my-media-kit': 'Media Kit',
    'brand-deals': 'Sponsorships',
    'archived-deal-tracker': 'Archived Deals',
    'calendar': 'Calendar',
    'sponsorship-leads': 'Sponsorship Leads',
    'brand-offers': 'Brand Sponsorships',
    'deal-manager': 'Brand Deal Manager',
    'add-deal': 'Brand Deal Manager',
    'edit-deal': 'Brand Deal Manager',
    'brand-sponsorships': 'Brand Sponsorships',
    'calendar-templates':'Contract Templates',
    'partner-perks':'Partner Perks',
    'insights':'Valuations',
    'community':'Community',
    'creator-coaching': 'Creator Coaching',
    'my-account': 'My Account',
    'business-docs': 'Business Docs',
    'billing': 'Billing',
    'cancel-subscription': 'Cancel Subscription',
    'sponsorship-101': 'Sponsorship 101',
    'sponsorship-intensive': 'Sponsorship Intensive',
    'upcoming-livestreams': 'Upcoming Livestreams',
    'compose-email': 'Email Composer',
    'contract-generator': 'Contract Generator',
    'contract-reviewer': 'Contract Reviewer'
};

export const social_names = {
    'facebook': 'Facebook',
    'youtube': 'YouTube',
    'twitter': 'Twitter',
    'tiktok': 'TikTok',
    'twitch': 'Twitch',
    'instagram': 'Instagram',
    'blog': 'Blog',
};

export const social_colors = {
    'facebook': '#0052FF',
    'youtube': '#D90700',
    'twitter': '#24B7FF',
    'tiktok': '#000000',
    'twitch': '#6A33D1',
    'instagram': '#D52D88',
    'blog': '#CD9900',
    'manual': '#24B7FF',
};

export const social_icons = {
    'facebook': <FacebookIcon viewBox="0 0 9 18"/>,
    'youtube': <YouTubeIcon viewBox="0 0 21 15"/>,
    'twitter': <TwitterIcon viewBox="0 0 15 12" />,
    'tiktok': <TikTokIcon viewBox="0 0 2859 3333"/>,
    'twitch': <TwitchIcon viewBox="0 0 17 18" />,
    'instagram': <InstagramIcon viewBox="0 0 18 18"/>,
    'blog': <BlogIcon viewBox="0 0 16 16"/>,
    'manual': <InterestsIcon />,
};

export const hiddenMenuPaths = [
    '/logout',
    '/subscriptions',
    '/confirm-email',
    '/get-started',
    '/confirm-free',
    '/logout/',
    '/subscriptions/',
    '/confirm-email/',
    '/get-started/',
    '/confirm-free/',
    '/voucher/',
    '/terms-of-use',
    '/privacy-policy',
    '/home',
    '/about-us',
];

export const hiddenPartialPaths = [
    '/confirm/',
    '/voucher/'
];

export const platformsSignOut = {
    'Youtube': {
        signOut: 'http://youtube.com',
        name : 'Youtube'
    },
    'Blog': {
        signOut: 'http://google.com',
        name : 'Blog'
    },
    'Instagram': {
        signOut: 'http://instagram.com',
        name : 'Instagram'
    },
    'Twitter': {
        signOut: 'http://twitter.com',
        name : 'Twitter'
    },
    'Facebook': {
        signOut: 'http://facebook.com',
        name : 'Facebook'
    },
    'Twitch': {
        signOut: 'https://www.twitch.tv/',
        name : 'Twitch'
    }
};

export const primaryPlatforms = [
    'Instagram',
    'Twitter',
    'YouTube',
    'Facebook',
    'Blog',
    'TikTok',
    'Twitch',
    'Pinterest',
    'Podcast',
]

export const creatorSizes = [
    {name: 'Nano-Creator (1-10,000 Followers)', value: 'nano'},
    {name: 'Micro-Creator (10,000 - 50,000 Followers)', value: 'micro'},
    {name: 'Mid-Tier Creator (50,000 - 500,000 Followers)', value: 'mid-tier'},
    {name: 'Macro-Creator (500,000 - 1M Followers)', value: 'macro'},
    {name: 'Mega-Creator (1M+ Followers)', value: 'mega'}
]

export const goalsLabel = {
    'achieve---collaborate-more': 'Collaborate with creators like me',
    'achieve---gain-more-followers': 'Gain more followers',
    'achieve---grow-as-a-creative': 'Build my brand and content strategy',
    'achieve---partner-with-brands': 'Receive brand sponsorships',
}


export const brandsInterested = [
    {name: 'Auto', value: 'brands---auto'},
    {name: 'Babies & Kids', value: 'brands---babies-and-kids'},
    {name: 'Beauty', value: 'brands---beauty'},
    {name: 'Electronics', value: 'brands---electronics'},
    {name: 'Fashion', value: 'brands---fashion'},
    {name: 'Fitness', value: 'brands---fitness'},
    {name: 'Food & Drink', value: 'brands---food-and-drink'},
    {name: 'Garden & Outdoors', value: 'brands---garden-and-outdoors'},
    {name: 'Health & Wellness', value: 'brands---health-and-wellness'},
    {name: 'Home', value: 'brands---home'},
    {name: 'Lifestyle', value: 'brands---lifestyle'},
    {name: 'Outdoors Equipment', value: 'brands---outdoors-equipment'},
    {name: 'Pets & Animals', value: 'brands---pets-and-animals'},
    {name: 'Technology', value: 'brands---technology'},
    {name: 'Tools & Equipment', value: 'brands---tools-and-equipment'},
    {name: 'Travel', value: 'brands---travel'},
];

export const growEngagementSection = {
    youtube: [
        {
            title: 'Group Coaching and Community',
            link: 'https://home.socialbluebook.com/discord-community/'
        },
        {
            title: 'Hire One-on-One Coaching',
            link: 'https://home.socialbluebook.com/creator-coaching/'
        },
        {
            title: '14 Steps to YouTube Success',
            link: 'https://academy.socialbluebook.com/courses/14-steps-to-youtube-success/'
        },
        {
            title: 'More Views on YouTube in 30 days',
            link: 'https://academy.socialbluebook.com/courses/more-views-on-youtube-in-30-days'
        },
    ],
    instagram: [
        {
            title: 'Group Coaching and Community',
            link: 'https://home.socialbluebook.com/discord-community/'
        },
        {
            title: 'Hire One-on-One Coaching',
            link: 'https://home.socialbluebook.com/creator-coaching/'
        },
        {
            title: 'Optimize your Instagram',
            link: 'https://academy.socialbluebook.com/courses/optimize-your-instagram'
        },
        {
            title: '#Hashtag this – a guide to using post captions',
            link: 'https://home.socialbluebook.com/hashtag-this-a-guide-to-using-post-captions/'
        },
    ],
    tiktok: [
        {
            title: 'Group Coaching and Community',
            link: 'https://home.socialbluebook.com/discord-community/'
        },
        {
            title: 'Hire One-on-One Coaching',
            link: 'https://home.socialbluebook.com/creator-coaching/'
        },
        {
            title: 'Master How To Make Money On TikTok',
            link: 'https://home.socialbluebook.com/master-how-to-make-money-on-tiktok-even-as-a-beginner/'
        },
        {
            title: 'How To Plan A Successful Social Media Content Strategy in 2021',
            link: 'https://home.socialbluebook.com/how-to-plan-a-successful-social-media-content-strategy-in-2021/'
        },
    ],
    facebook: [
        {
            title: 'Group Coaching and Community',
            link: 'https://home.socialbluebook.com/discord-community/'
        },
        {
            title: 'Hire One-on-One Coaching',
            link: 'https://home.socialbluebook.com/creator-coaching/'
        },
        {
            title: 'What Skills Do You Need To Be A Successful Content Creator',
            link: 'https://home.socialbluebook.com/what-skills-do-you-need-to-be-a-successful-content-creator/'
        },
        {
            title: 'How To Plan A Successful Social Media Content Strategy in 2021',
            link: 'https://home.socialbluebook.com/how-to-plan-a-successful-social-media-content-strategy-in-2021/'
        },
    ],
    twitch: [
        {
            title: 'Group Coaching and Community',
            link: 'https://home.socialbluebook.com/discord-community/'
        },
        {
            title: 'Hire One-on-One Coaching',
            link: 'https://home.socialbluebook.com/creator-coaching/'
        },
        {
            title: 'Become a Successful Live Streamer in Under 30 days',
            link: 'https://academy.socialbluebook.com/courses/live-streamer-in-30days/'
        },
        {
            title: 'How To Plan A Successful Social Media Content Strategy in 2021',
            link: 'https://home.socialbluebook.com/how-to-plan-a-successful-social-media-content-strategy-in-2021/'
        },
    ],
    twitter: [
        {
            title: 'Group Coaching and Community',
            link: 'https://home.socialbluebook.com/discord-community/'
        },
        {
            title: 'Hire One-on-One Coaching',
            link: 'https://home.socialbluebook.com/creator-coaching/'
        },
        {
            title: 'What Skills Do You Need To Be A Successful Content Creator',
            link: 'https://home.socialbluebook.com/what-skills-do-you-need-to-be-a-successful-content-creator/'
        },
        {
            title: 'How To Plan A Successful Social Media Content Strategy in 2021',
            link: 'https://home.socialbluebook.com/how-to-plan-a-successful-social-media-content-strategy-in-2021/'
        },
    ],
    blog: [
        {
            title: 'Group Coaching and Community',
            link: 'https://home.socialbluebook.com/discord-community/'
        },
        {
            title: 'Hire One-on-One Coaching',
            link: 'https://home.socialbluebook.com/creator-coaching/'
        },
        {
            title: 'What Skills Do You Need To Be A Successful Content Creator',
            link: 'https://home.socialbluebook.com/what-skills-do-you-need-to-be-a-successful-content-creator/'
        },
        {
            title: 'How To Plan A Successful Social Media Content Strategy in 2021',
            link: 'https://home.socialbluebook.com/how-to-plan-a-successful-social-media-content-strategy-in-2021/'
        },
    ],
};

export const messages = {
    'RESET-PASSWORD-MESSAGE': 'The email already exists, if you forgot your password please change it here.',
    'INVALID-EMAIL': 'Invalid email.'
};

export const weekdays = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

export const mediaKitFAQ = [
    {
        title: 'Why do I need a media kit?',
        response: 'A Media Kit is the best way for creators to share audience metrics & demographics with brands. A creator is much more likely to work with a brand when the brand can see that the creator has the type of audience they\'re looking for.'
    },
    {
        title: 'Exactly what type of information is included in a Media Kit?',
        response: 'Your Media Kit should include social media metrics such as reach and engagement. Reach metrics include follower counts and/or average views per post, etc. Engagement metrics include average # of likes, comments, but also could include metrics like shares and saves as well. Lastly, your Media Kit will highlight your audience demographics. This includes their age, gender, and where they are located. This is all important information that a brand will want to know.'
    },
    {
        title: 'Why should I use Social Bluebook\'s Media Kit?',
        response: 'Every single time you send a Media Kit to a brand you\'ll need to update all of your metrics. Social Bluebook users can easily hit refresh on their social accounts and we will update all of that information for you. Piece of cake! Also as you progress on your creator journey will SBB you will earn special badges on your Media Kit that will help you stand out from other creators so you can maximize your income.'
    }
]

export const primaryActivePlatforms = {
    Instagram: {
        platform_username: {
            type: "text",
            value: null
        },
        platform_followers: {
            type: "number",
            value: null
        },
        average_comments: {
            type: "number",
            value: null
        },
        average_likes: {
            type: "number",
            value: null
        },
        mediakit_show: {
            type: "checkbox",
            value: null
        }
    },
    YouTube: {
        platform_username: {
            type: "text",
            value: null
        },
        platform_followers: {
            type: "number",
            value: null
        },
        average_video_views: {
            type: "number",
            value: null
        },
        average_comments: {
            type: "number",
            value: null
        },
        average_video_likes: {
            type: "number",
            value: null
        },
        mediakit_show: {
            type: "checkbox",
            value: null
        }
    },
    TikTok: {
        platform_username: {
            type: "text",
            value: null
        },
        platform_followers: {
            type: "number",
            value: null
        },
        average_video_views: {
            type: "number",
            value: null
        },
        average_comments: {
            type: "number",
            value: null
        },
        average_video_likes: {
            type: "number",
            value: null
        },
        mediakit_show: {
            type: "checkbox",
            value: null
        }
    },
    Facebook: {},
    Blog: {},
    Twitch: {},
}